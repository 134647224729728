import React from 'react';
import { Menu } from 'antd';

const NavigationSide = ({ setActiveSection }) => {
  return (
    <Menu mode="inline" style={{ width: 256, height: 'auto', overflowY: 'auto', overflowX: 'hidden' }}>
      <Menu.Item key="fieldsTable" onClick={() => setActiveSection('fieldsTable')}>Fields Table</Menu.Item>
      <Menu.Item key="ingestingLeads" onClick={() => setActiveSection('ingestingLeads')}>Ingesting Leads</Menu.Item>
      {/* <Menu.Item key="testing" onClick={() => setActiveSection('testing')}>Testing</Menu.Item> */}
      <Menu.Item key="activeFilters" onClick={() => setActiveSection('activeFilters')}>Active Filters</Menu.Item>
      <Menu.Item key="responseMessages" onClick={() => setActiveSection('responseMessages')}>Response Messages</Menu.Item>
      <Menu.Item key="getHelp" onClick={() => setActiveSection('getHelp')}>Get Help</Menu.Item>
    </Menu>
  );
};

export default NavigationSide;
