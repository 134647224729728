import React from 'react';

const ActiveFilters = ({ data }) => {
  console.log("ActiveFilters: ", data);
  
  // Function to group data by Filter ID
  const groupByFilterID = (data) => {
    const grouped = {};
    data.forEach((item) => {
      if (!grouped[item["Filter ID"]]) {
        grouped[item["Filter ID"]] = [];
      }
      grouped[item["Filter ID"]].push(item);
    });
    return grouped;
  };

  const groupedData = groupByFilterID(data);

  function getDescription(expression) {
    switch (expression) {
      case "EQUALS":
        return "Field Value Must Equal";
      case "ANDEXISTS":
        return "Field(s) Must Exist";
      case "OREXISTS":
        return "One of the Fields Must Exist";
      case "NOTEQUALS":
        return "Field Value must NOT Equal";
      case "OR":
        return "Field Value must contain either";
      default:
        return expression;
    }
  }

  // Function to clean filterValue
  const cleanFilterValue = (value) => {
    if (!value || value === "[]") {
      return "";
    }
    // Remove special characters
    return value.replace(/[\[\]\"]/g, '');
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2 style={{ fontSize: '30px', color: 'Black', textAlign: 'left' }}>Active Filters</h2>
      <p style={{ fontSize: '18px', color: 'grey', textAlign: 'left' }}>
        The table below contains the active filters.
      </p>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Filter ID</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Name</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Condition</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Value</th>
            {/* <th style={{ border: '1px solid #ddd', padding: '8px', backgroundColor:'rgb(250, 251, 251)' }}>Required</th> */}
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedData).map((filterID) => {
            // 获取当前 Filter ID 下所有的 fields
            const fields = groupedData[filterID].filter(field => field['Priority Level'] !== 0);
            if (fields.length === 0) {
              return null; 
            }

            // 获取唯一条件
            const uniqueConditions = [...new Set(fields.map(field => getDescription(field['Expression'])))];
            // 获取所有的值，并清除特殊字符
            const uniqueValues = [...new Set(fields.map(field => cleanFilterValue(field['filterValue'])))];

            return (
              <tr key={filterID}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{filterID}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {fields.map((field) => field['Descript']).join(', ')}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {uniqueConditions.join(', ')}
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {uniqueValues.join(', ')}
                </td>
                {/* <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {fields.every((field) => field['Priority Level'] === 1) ? "Yes" : "No"}
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveFilters;
