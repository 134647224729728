import React, { useRef, useEffect } from 'react';
import FieldsTable from './APIDocumentFieldsTable';
import IngestingLeadsRef from './APIDocumentIngestingLeads';
import APIDocumentTesting from './APIDocumentTesting';
import ResponseMessages from './APIDocumentResponseMessage';
import APIDocumentGetHelp from './APIDocumentGetHelp';
import ActiveFilters from "./APIDocumentActiveFilters";

const ContentSection = ({ activeSection, data }) => {

    


    const fieldsTableRef = useRef(null);
    const ingestingLeadsRef = useRef(null);
    const testingRef = useRef(null);
    const activeRef = useRef(null);
    const responseMessagesRef = useRef(null);
    const getHelpRef = useRef(null);
  
    useEffect(() => {
      switch(activeSection) {
        case 'fieldsTable':
          fieldsTableRef.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'ingestingLeads':
          ingestingLeadsRef.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case 'testing':
            testingRef.current?.scrollIntoView({ behavior: 'smooth' });
            break;
        case 'activeFilters':
            activeRef.current?.scrollIntoView({ behavior: 'smooth' });
            break;
        case 'responseMessages':
            responseMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
            break;
        case 'getHelp':
            getHelpRef.current?.scrollIntoView({ behavior: 'smooth' });
            break;
      }
    }, [activeSection]);
  

    console.log('data: ',data);
    const fieldsData = data && data[0] ? data[0]['Category Mapping'] : [];
    const filterData = data && data[0] ? data[0]['Filter Mapping']:[];

    // Check if fieldsData is empty and return a message if true
    if (fieldsData === null || fieldsData.length === 0) {
        return (
        <div style={{ padding: '20px' }}>
            <p style={{ fontSize: '18px', color: 'grey' }}>
            No Data.
            </p>
        </div>
        );
    }

    return (
      <div>
        <div ref={fieldsTableRef}>
            {data ? <FieldsTable data={data} /> : <p>Loading data...</p>}
        </div>

        
        <div ref={ingestingLeadsRef}>
            {data ? <IngestingLeadsRef data={data} /> : <p>Loading ingestion leads example...</p>}
        </div>
        
        {/* <div ref={testingRef}>
            <APIDocumentTesting />
        </div> */}
        <div ref={activeRef}>
             {data ? <ActiveFilters data={filterData} /> : <p>Loading data...</p>}
        </div>


        <div ref={responseMessagesRef}>
            <ResponseMessages />
        </div>


        <div ref={getHelpRef}>
            <APIDocumentGetHelp />
        </div>
      </div>
    );
  };
  

export default ContentSection;
